import styled from "styled-components"
import Grid from "../grid"

export const Container = styled(Grid)`
  box-sizing: border-box;
`
Container.defaultProps = {
  bg: "transparent",
  py: ["y.mobile.4.0", "y.desktop.4.0"],
  px: [0, "x.4", 0],
  gridTemplateColumns: [
    "[main-start] repeat(4, 1fr) [main-end]",
    "[main-start] repeat(8, 1fr) [main-end]",
    "1fr [main-start] repeat(12, 1fr) [main-end] 1fr ",
  ],
}

export const Inner = styled(Grid.Item)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`
Inner.defaultProps = {
  gridColumn: "main-start / main-end",
}

export const Vimeo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  transform-origin: center center;
`

export const Youtube = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  transform-origin: center center;
`

export const Controls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`
