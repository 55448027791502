import React, { useEffect } from "react"
import axios from "axios"
import { Container } from "./instagram.styles"

export default ({ slice }) => {
  // useEffect(async () => {
  //   console.log("fetch instagram")

  //   const res = await axios.get(
  //     `https://graph.facebook.com/v3.1/${instagram_id}/media?fields=media_url,thumbnail_url,caption,media_type,like_count,shortcode,timestamp,comments_count,username&limit=${paginate}&access_token=${access_token}`
  //   )

  //   console.log(res)
  // })

  return <Container>@ohbrother {slice.primary.username}</Container>
}
