import React, { useEffect, useRef } from "react"
import { RichText } from "prismic-reactjs"
import { useMediaQuery } from "react-responsive"
import { Container, LogoImage, Title, IntroText } from "./project-header.styles"

export default ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: "40em" })
  const container = useRef()

  useEffect(() => {
    // Custom iOS stuff
    if (!isMobile) {
      if (container.current) container.current.style.height = ""
    } else {
      const height = `${window.innerHeight - 108}px`
      if (container.current) container.current.style.height = height
    }
  }, [])

  return (
    <Container ref={container}>
      {data.logo_image.fixed ? (
        <LogoImage
          fixed={data.logo_image.fixed}
          alt={data.logo_image.alt}
          imgStyle={{ objectFit: "contain" }}
        />
      ) : (
        <Title>{data.name}</Title>
      )}
      <IntroText>{data.storyline.text}</IntroText>
    </Container>
  )
}
