import React from "react"
import { Container, Heading, Inner, Label, Text } from "./details.styles"
import Grid from "../grid"

export default ({ slice }) => {
  return (
    <Container>
      <Heading>Details</Heading>
      <Inner>
        {slice.items.map((field, index) => (
          <Grid.Item
            key={`field-${index}`}
            gridColumn={["span 2", "span 3"]}
            gridRow={["auto", "auto", index % 2 ? 2 : 1]}
            mt={["y.mobile.1.0", "y.desktop.1.0"]}
          >
            <Label>{field.label}</Label>
            <Text>{field.text}</Text>
          </Grid.Item>
        ))}
      </Inner>
    </Container>
  )
}
