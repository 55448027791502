import React from "react"
import styled from "styled-components"

const Container = styled.div`
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSpinner = styled.i`
  & {
    transform: scale(var(--ggs, 1));
  }
  &,
  &::after,
  &::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 6.4rem;
    height: 6.4rem;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    border-radius: 100px;
  }
  &::before {
    animation: spinner 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
    border: 3px solid transparent;
    border-top-color: #fff;
  }
  &::after {
    border: 3px solid #fff;
    opacity: 0.4;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
export const Spinner = React.forwardRef((props, ref) => {
  return (
    <Container>
      <StyledSpinner {...props} ref={ref} icon-role="spinner" />
    </Container>
  )
})

export default Spinner
