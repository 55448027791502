import React, { useState } from "react"
import Img from "gatsby-image"
import { Container, Heading, Inner, Laurel } from "./awards.styles"

export default ({ slice }) => {

  return (
    <Container>
      <Heading>Awards</Heading>
      <Inner>
        {slice.items.map((item, index) => {
          return (
            <Laurel key={`laurel-${index}`}>
              <Img
                fixed={item.laurel_image.fixed}
                alt={item.laurel_image.alt}
              />
            </Laurel>
          )
        })}
      </Inner>
    </Container>
  )
}
