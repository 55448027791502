import styled from "styled-components"
import { grid } from "styled-system"
import Grid from "../grid"
import Typo from "../text"
import StoryImg from "../../images/story.svg"

export const Container = styled(Grid)``
Container.defaultProps = {
  py: ["y.mobile.4.0", "y.desktop.4.0"],
  gridTemplateColumns: [
    "[main-start text-start aside-start] repeat(4, 1fr) [aside-end text-end main-end]",
    "[main-start text-start aside-start] repeat(5, 1fr) [text-end] 1fr [aside-end main-end]",
    "[main-start text-start] repeat(5, 1fr) [text-end] 1fr [aside-start] repeat(4, 1fr) [aside-end main-end]",
  ],
}

export const Aside = styled(Grid)``
Aside.defaultProps = {
  gridTemplateColumns: ["repeat(4, 1fr)"],
  gridTemplateRows: "1fr 1fr",
  gridColumnGap: ["1.6rem", "3.2rem", 0],
  gridColumn: "span 4",
  alignSelf: "flex-start",
}

export const Item = Grid.Item
Item.defaultProps = {
  gridColumn: "text-start / span 5",
}

export const Heading = styled(Typo.H3)`
  ${grid}

  position: relative;

  &::before {
    position: absolute;
    top: 1.2rem;
    left: -3.2rem;
    display: block;
    width: 2rem;
    height: 3rem;
    background-image: url(${StoryImg});
    background-repeat: no-repeat;
    background-size: auto 100%;
    transform: translate3d(-100%, 0, 0);
    content: "";
  }
`
Heading.defaultProps = {
  gridColumn: "main-start / main-end",
  mt: 0,
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
}

export const Label = styled(Typo)`
  display: block;
`
Label.defaultProps = {
  as: "strong",
  fontFamily: "mono",
  fontWeight: 6,
  fontSize: ["mobile.-2", "desktop.-2"],
}

export const Text = styled(Typo)`
  display: block;
`
Text.defaultProps = {
  fontFamily: "mono",
  fontSize: ["mobile.-2", "desktop.-2"],
}
