import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { withPreview } from "gatsby-source-prismic"

import PageWrapper from "../components/page-wrapper"
import SEO from "../components/seo"
import Grid from "../components/grid"
import ProjectHeader from "../components/project-header"
import ProjectBody from "../components/project-body"

export const query = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      id
      uid
      prismicId
      type
      data {
        name
        storyline {
          text
          raw
        }
        logo_image {
          fixed(height: 70) {
            ...GatsbyPrismicImageFixed
          }
        }
        body {
          ... on PrismicProjectBodyVideo {
            slice_type
            slice_label
            primary {
              url {
                embed_url
                provider_name
              }
            }
          }
          ... on PrismicProjectBodyText {
            slice_type
            slice_label
            primary {
              headline {
                text
                raw
              }
              text {
                text
                raw
              }
            }
            items {
              label
              text
            }
          }
          ... on PrismicProjectBodyDetails {
            slice_type
            slice_label
            items {
              label
              text
            }
          }
          ... on PrismicProjectBodyInstagram {
            slice_type
            slice_label
            primary {
              username
            }
          }
          ... on PrismicProjectBodyAwards {
            slice_type
            slice_label
            items {
              award
              laurel_image {
                fixed(width: 84) {
                  ...GatsbyPrismicImageFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

const ProjectPage = (props) => {
  const project = get(props, `data.prismicProject`)
  if (!project) return null
  if (!project.data.storyline.text) return null

  return (
    <PageWrapper name="project">
      <SEO
        title={project.data.name}
        description={project.data.storyline.text}
        image={project.data.cover}
      />

      <Grid>
        {/* <SubNav /> */}
        <ProjectHeader data={project.data} />
        <ProjectBody data={project.data.body} />
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(ProjectPage)
