import styled from "styled-components"
import { layout } from "styled-system"
import Img from "gatsby-image"
import Grid from "./grid"
import Text from "./text"

export const Container = styled(Grid.Item)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
Container.defaultProps = {
  as: "section",
  gridColumn: "intro-start / intro-end",
  height: ["calc(100vh - 12rem)", "calc(100vh - 16.8rem)"],
}

export const LogoImage = styled(Img)`
  ${layout}
  user-select: none;
`
LogoImage.defaultProps = {
  maxHeight: "heights.desktop.2.5",
}

export const Title = styled(Text.H3)``
Title.defaultProps = {
  as: "h2",
  mt: 0,
  mb: 0,
}

export const IntroText = styled(Text.H4)``
IntroText.defaultProps = {
  fontFamily: "sansSerif",
  fontWeight: 4,
  fontSize: ["mobile.1", "desktop.2"],
  lineHeight: ["mobile.2.0", "desktop.2.0"],
  textAlign: "center",
  mt: "1.6rem",
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
  color: "black.6",
}
