import React from "react"
import Grid from "./grid"
import Video from "./slices/video"
import Text from "./slices/text"
import Details from "./slices/details"
import Instagram from "./slices/instagram"
import Awards from "./slices/awards"

export default ({ data }) => {
  return data.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "video":
          return (
            <Grid.Item
              as="section"
              key={`slice-${index}`}
              gridColumn="full-start / full-end"
              bg="black.0"
            >
              <Video slice={slice} />
            </Grid.Item>
          )

        case "text":
          return (
            <Grid.Item
              as="section"
              key={`slice-${index}`}
              gridColumn="text-start / text-end"
            >
              <Text slice={slice} />
            </Grid.Item>
          )

        case "details":
          return (
            <Grid.Item
              as="section"
              key={`slice-${index}`}
              gridColumn="full-start / full-end"
            >
              <Details slice={slice} />
            </Grid.Item>
          )

        case "awards":
          return (
            <Grid.Item
              as="section"
              key={`slice-${index}`}
              gridColumn="full-start / full-end"
            >
              <Awards slice={slice} />
            </Grid.Item>
          )

        case "instagram":
          return null
          return (
            <Grid.Item
              as="section"
              key={`slice-${index}`}
              gridColumn="main-start / main-end"
            >
              <Instagram slice={slice} />
            </Grid.Item>
          )

        default:
          return
      }
    })()
    return res
  })
}
