import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import { Container, Heading, Aside, Item, Label, Text } from "./text.styles"

export default ({ slice }) => {
  return (
    <Container>
      <Heading>{slice.primary.headline.text}</Heading>
      {!!slice.primary.text.text.length && (
        <Item>
          <RichText
            render={slice.primary.text.raw}
            htmlSerializer={htmlSerializer()}
            linkResolver={linkResolver}
          />
        </Item>
      )}
      <Aside
        gridColumn={
          slice.primary.text.text.length
            ? [
                "aside-start / aside-end",
                "aside-start / aside-end",
                "aside-start / span 4",
              ]
            : ["aside-start / aside-end", "aside-start / aside-end", "span 4"]
        }
      >
        {slice.items.map((field, index) => (
          <Item
            key={`field-${index}`}
            gridColumn="span 2"
            mt={["y.mobile.1.0", "y.desktop.1.0"]}
          >
            <Label>{field.label}</Label>
            <Text>{field.text}</Text>
          </Item>
        ))}
      </Aside>
    </Container>
  )
}
