import styled from "styled-components"
import { grid } from "styled-system"
import Grid from "../grid"
import DefaultText from "../text"
import SquareImg from "../../images/square.svg"

export const Container = styled(Grid)``
Container.defaultProps = {
  py: ["y.mobile.4.0", "y.desktop.4.0"],
  gridTemplateColumns: [
    "2.4rem [main-start] repeat(4, 1fr) [main-end] 2.4rem",
    "1fr [main-start] repeat(8, 1fr) [main-end] 1fr",
    "1fr 1fr [main-start] repeat(10, 1fr) [main-end] 1fr 1fr",
  ],
  bg: "secondary",
}

export const Inner = styled(Grid)``
Inner.defaultProps = {
  bg: "transparent",
  gridTemplateColumns: ["repeat(4, 1fr)", "repeat(6, 1fr)", "repeat(10, 1fr)"],
  gridTemplateRows: "1fr 1fr",
  gridColumn: "main-start / main-end",
}

export const Heading = styled(DefaultText.H3)`
  ${grid}

  position: relative;
  user-select: none;

  &::before {
    position: absolute;
    top: 1.8rem;
    left: -3.2rem;
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    background-image: url(${SquareImg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    transform: translate3d(-100%, 0, 0);
    content: "";
  }
`
Heading.defaultProps = {
  gridColumn: "main-start / main-end",
  mt: 0,
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
  color: "white",
}

export const Label = styled(DefaultText)`
  display: block;
  user-select: none;
`
Label.defaultProps = {
  as: "strong",
  fontFamily: "mono",
  fontWeight: 6,
  fontSize: ["mobile.-2", "desktop.-2"],
  color: "#BFBFBF",
}

export const Text = styled(DefaultText)`
  display: block;
  ::selection {
    background: #fff;
    color: #000;
  }
`
Text.defaultProps = {
  fontFamily: "mono",
  fontSize: ["mobile.-2", "desktop.-2"],
  color: "#BFBFBF",
}
