import React, { useRef, useEffect, useState } from "react"
import "intersection-observer"
import { useInView } from "react-intersection-observer"
import Player from "@vimeo/player"
import { Container, Inner, Vimeo, Youtube, Controls } from "./video.styles"
import Spinner from "../spinner"
import Play from "../play"
import {
  getVideoIdFromVimeoUrl,
  getVideoIdFromYoutubeUrl,
} from "../../utils/getVideoIdFromEmbedUrl"

export default ({ slice }) => {
  const [container, inView] = useInView({ threshold: 0.5 })

  const [backgroundPlay, setBackgroundPlay] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const player = useRef()
  const video = useRef()

  const handlePlay = () => {
    setBackgroundPlay(false)
  }

  const play = async () => {
    const isPaused = await player.current.getPaused()
    if (isPaused) player.current.play()
  }

  const pause = async () => {
    const isPaused = await player.current.getPaused()
    if (!isPaused) player.current.pause()
  }

  const buffered = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    if (!video.current) return
    player.current = new Player(video.current)
    player.current.on("bufferend", buffered)
    ;() => {
      if (player.current) {
        player.current.off("buffered", buffered)
        player.current.destroy()
      }
    }
  }, [video])

  useEffect(() => {
    if (!player.current) return
    //throttle this calls
    inView ? play() : pause()
  }, [inView])

  const renderVideo = (provider) => {
    if (!provider) return null

    switch (provider.provider_name) {
      case "YouTube":
        return renderYoutube(provider)

      case "Vimeo":
        return renderVimeo(provider)

      default:
        return null
    }
  }

  const renderYoutube = (youtube) => {
    const videoId = getVideoIdFromYoutubeUrl(youtube.embed_url)
    if (!videoId) return null

    return (
      <Youtube
        src={`//www.youtube.com/embed/${videoId}?autoplay=0&showinfo=0&controls=0`}
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        allow="autoplay"
      />
    )
  }

  const renderVimeo = (vimeo) => {
    const videoId = getVideoIdFromVimeoUrl(vimeo.embed_url)
    if (!videoId) return null

    return (
      <>
        <Vimeo
          ref={video}
          src={`https://player.vimeo.com/video/${videoId}?background=${
            backgroundPlay ? "1" : "0"
          }&autoplay=1&loop=${backgroundPlay ? "1" : "0"}&muted=${
            backgroundPlay ? "1" : "0"
          }&byline=0&title=0`}
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          allow="autoplay; fullscreen"
        />
        <Controls onClick={!isLoading && handlePlay} disabled={!backgroundPlay}>
          {!isLoading && <Play active={backgroundPlay} />}
          {isLoading && <Spinner />}
        </Controls>
      </>
    )
  }

  return (
    <Container ref={container}>
      <Inner>{renderVideo(slice.primary.url)}</Inner>
    </Container>
  )
}
